import { firebase } from "@firebase/app";
import "@firebase/analytics";
import "@firebase/auth";
import "@firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyD2_WGPypv_d62yn_99lvfCl0jdBEDO0qk",
  authDomain: "adeka-79878.firebaseapp.com",
  databaseURL: "https://adeka-79878.firebaseio.com",
  projectId: "adeka-79878",
  storageBucket: "",
  messagingSenderId: "13665702279",
  appId: "1:13665702279:web:3feed01db7891e01a64322",
  measurementId: "G-P9GZ0R5BG3"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

firebase.firestore().enablePersistence();

// Sign in the user anonymously
firebase
  .auth()
  .signInAnonymously()
  .catch(function(error) {
    console.error(error);
  });

firebase.auth().onAuthStateChanged(user => {
  if (user) {
    window.dispatchEvent(
      new CustomEvent("auth-state-changed", {
        bubbles: true,
        detail: { loggedIn: true, user }
      })
    );
  } else {
    window.dispatchEvent(
      new CustomEvent("auth-state-changed", {
        bubbles: true,
        detail: { loggedIn: false }
      })
    );
  }
});

export default firebase;
