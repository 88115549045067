import io from "socket.io-client";
const liveCountServer = "https://adeka-live-user-count.herokuapp.com";

let countWrapper;
let countValueDiv;

const initializeDOMElements = () => {
  countWrapper = document.getElementById("live-user-count");
  countValueDiv = countWrapper.querySelector(".live-user-count-value");
};

const getUserCountText = (count) => {
  if (count == 1) {
    return "1 WATCHER ONLINE";
  }

  return `${count} WATCHERS ONLINE`;
};

const updateUserCount = ({ userCount }) => {
  if (!(countWrapper && countValueDiv)) {
    initializeDOMElements();
  }

  if (userCount === 0) {
    countWrapper.classList.remove("has-users");
    countValueDiv.innerText = "";
    return;
  }

  countWrapper.classList.add("has-users");
  countValueDiv.innerText = getUserCountText(userCount);

  countWrapper.classList.remove("user-count-updated");
  requestAnimationFrame(() => {
    countWrapper.classList.add("user-count-updated");
    setTimeout(() => {
      countWrapper.classList.remove("user-count-updated");
    }, 300);
  });
};

window.addEventListener("DOMContentLoaded", () => {
  setTimeout(() => {
    var socket = io(liveCountServer, {
      transports: ["websocket"],
    });

    socket.on("reconnect_attempt", () => {
      socket.io.opts.transports = ["websocket"];
    });

    socket.on("userCount", updateUserCount);
    socket.on("error", console.error);
  }, 100);
});
