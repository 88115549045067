const NumberFormatter = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

export const getFormattedNumber = number => {
  return NumberFormatter.format(number);
};

var optionsLong = { day: "numeric", year: "numeric", month: "short" };
var DateTimeFormatterLong = new Intl.DateTimeFormat("en-US", optionsLong);

var optionsShort = { day: "numeric", month: "short" };
var DateTimeFormatterShort = new Intl.DateTimeFormat("en-US", optionsShort);

const getMinutes = (diff) => {
  return Math.floor((diff % 3600000) / 60000);
}

const getHours = (diff) => {
  return Math.floor((diff % 86400000) / 3600000);
}

export const getFormattedTime = date => {
  const now = new Date();
  const diff = now - date;
  if (diff < 60000) {
    return "Just now";
  }
  if (diff < 3600000) {
    return `${getMinutes(diff)}m`;
  }

  if (diff < 86400000) {
    return `${getHours(diff)}h`;
  }

  if (date.getYear() !== now.getYear()) {
    return DateTimeFormatterLong.format(date);
  }

  return DateTimeFormatterShort.format(date);
};
