import "../vendors/cloudinary";

cloudinary.setCloudName("adeka");

let widget = null;
let callback = result => {
  // console.log("result", result);
};

const createWidget = () => {
  widget = cloudinary.createUploadWidget(
    {
      uploadPreset: "cwuif9si",
      sources: ["local", "ur", "camera"],
      maxFiles: 15,
      cropping: true,
      croppingAspectRatio: 2,
      maxImageFileSize: 150000000,
      maxImageWidth: 720,
      showSkipCropButton: false,
      styles: {
        palette: {
          window: "#FFF",
          windowBorder: "#84888c",
          tabIcon: "#2db573",
          menuIcons: "#4e5255",
          textDark: "#4e5255",
          textLight: "#fff",
          link: "#2db573",
          action: "#2db573",
          inactiveTabIcon: "#2db573",
          error: "#F44235",
          inProgress: "#4e5255",
          complete: "#2db573",
          sourceBg: "#ecf0f1"
        },
      }
    },
    (error, result) => {
      if (error) {
        console.error(error);
        return;
      }

      callback(result);
    }
  );
};

let timeoutInitial;
let timeoutSecondary;

timeoutInitial = setTimeout(() => {
  timeoutSecondary = setTimeout(() => {
    createWidget();
  }, 1000);
}, 0);

export const openWidget = _callback => {
  if (_callback) {
    callback = _callback;
  }

  clearTimeout(timeoutInitial);
  clearTimeout(timeoutSecondary);
  if (!widget) {
    createWidget();
  }

  widget.open();
};

export const closeWidget = () => {
  widget.close();
}