console.log(
  `%cPost your ads about anything for FREE
%cwhere everyone can see, without limits
%cad%cEka.lk
%c📰 Awesome? Spread the News!
👨‍🍳 Feature requests? ahtimadhusanka@gmail.com
🐛 Bugs or Issues? ahtimadhusanka@gmail.com
%cDisclaimer: This is currently a free service with no full time dedicated resources. Any disputes will be attended to, eventually. Please be patient. 
`,
  "font:1.3em sans-serif;padding-top:10px;color:#4e5255;",
  "font:1.3em sans-serif;padding-bottom:5px;color:#4e5255;",
  "font:bold 6em sans-serif;padding-bottom:10px;color:#2db573",
  "font:bold 6em sans-serif;padding-bottom:10px;color:#4e5255",
  "font-size:1rem;padding-bottom:5px",
  "font:1em sans-serif;padding-top:10px;color:#4e5255;"
);
