let logoUpdateInterval = null;
window.addEventListener("DOMContentLoaded", () => {
  let logos = document.querySelectorAll(".adeka-logo");
  if (logos.length === 0) {
    return;
  }

  clearInterval(logoUpdateInterval);
  logoUpdateInterval = setInterval(() => {
    logos.forEach((logo) => {
      logo.classList.toggle("adeka-logo--with-si");
    });
  }, Math.random() * 5000 + 5000);
});
