import { ORDER_BY_FIELD } from "../constants/orderByField";

const containerAppBarSortActions = document.getElementById("actions-sort");
const buttonNewPost = document.getElementById("button-new-post");
const buttonSortByDate = document.getElementById("button-sort-date");
const buttonSortByPrice = document.getElementById("button-sort-price");
const heroImage = document.getElementById("hero");

// Listen for the event.
window.addEventListener(
  "auth-state-changed",
  (e) => {
    if (e.detail.loggedIn) {
      buttonNewPost.classList.remove("disabled");
      containerAppBarSortActions.classList.remove("disabled");
    } else {
      buttonNewPost.classList.add("disabled");
      containerAppBarSortActions.classList.add("disabled");
    }
  },
  true
);

// Listen for the event.
window.addEventListener(
  "auth-state-changed",
  (e) => {
    if (e.detail.loggedIn) {
      buttonNewPost.classList.remove("disabled");
      containerAppBarSortActions.classList.remove("disabled");
    } else {
      buttonNewPost.classList.add("disabled");
      containerAppBarSortActions.classList.add("disabled");
    }
  },
  true
);

buttonNewPost.addEventListener("click", () => {
  const newPostForm = document.getElementById("new-post-form");
  if (!newPostForm) {
    return;
  }

  newPostForm.scrollIntoView({
    behavior: "smooth",
  });

  setTimeout(() => {
    requestAnimationFrame(() => {
      document.getElementById("new-post-form-title").focus();
    });
  }, 700);
});

const scrollToTop = () => {
  heroImage.scrollIntoView({
    behavior: "smooth",
  });
};

const triggerSort = (orderByField) => {
  const sortEvent = new CustomEvent("adeka-sort-change", {
    detail: { orderByField },
  });

  window.dispatchEvent(sortEvent);
  requestAnimationFrame(scrollToTop);
};

const arrowIconUp = "icon-arrow-up";
const arrowIconDown = "icon-arrow-down";

buttonSortByDate.addEventListener("click", () => {
  const icon = buttonSortByDate.querySelector(".icon");
  if (!buttonSortByDate.classList.contains("accent")) {
    const otherIcon = buttonSortByPrice.querySelector(".icon");
    otherIcon.classList.remove(arrowIconDown);
    otherIcon.classList.remove(arrowIconUp);
    icon.classList.add(arrowIconDown);
  } else {
    if (icon.classList.contains(arrowIconDown)) {
      icon.classList.remove(arrowIconDown);
      icon.classList.add(arrowIconUp);
    } else {
      icon.classList.remove(arrowIconUp);
      icon.classList.add(arrowIconDown);
    }
  }

  buttonSortByDate.classList.add("accent");
  buttonSortByPrice.classList.remove("accent");

  triggerSort(ORDER_BY_FIELD.createdOn);
});

buttonSortByPrice.addEventListener("click", () => {
  const icon = buttonSortByPrice.querySelector(".icon");
  if (!buttonSortByPrice.classList.contains("accent")) {
    const otherIcon = buttonSortByDate.querySelector(".icon");
    otherIcon.classList.remove(arrowIconDown);
    otherIcon.classList.remove(arrowIconUp);
    icon.classList.add(arrowIconDown);
  } else {
    if (icon.classList.contains(arrowIconDown)) {
      icon.classList.remove(arrowIconDown);
      icon.classList.add(arrowIconUp);
    } else {
      icon.classList.remove(arrowIconUp);
      icon.classList.add(arrowIconDown);
    }
  }

  buttonSortByPrice.classList.add("accent");
  buttonSortByDate.classList.remove("accent");

  triggerSort(ORDER_BY_FIELD.price);
});
