// import './vanilla/heroImageScrolling';
import "./vanilla/lightbox";
import "./vanilla/animateLogo";
import "./vanilla/enableDisableAppBarButtons";
import "./vanilla/liveUserCount";
import "./vanilla/intro";

import App from "./App.svelte";
import firebase from "./firebase";

import "./style/main.scss";

const app = new App({
  target: document.getElementById("app"),
  props: {
    firebase,
  },
});

export default app;
