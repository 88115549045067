window.addEventListener("DOMContentLoaded", () => {
  let lightBox = document.getElementById("light-box");
  let lightBoxImage = document.getElementById("light-box__image");

  window.addEventListener("adeka-image-press", (e) => {
    const { url } = e.detail || {};
    lightBoxImage.style.backgroundImage = `url(${url})`;
    lightBox.classList.add("light-box--visible");

    const removeLightbox = () => {
      lightBox.classList.remove("light-box--visible");
      document.removeEventListener("mouseup", removeLightbox);
    };

    document.addEventListener("mouseup", removeLightbox);
  });
});
