<script>
  import { fly } from "svelte/transition";
  import throttle from "lodash-es/throttle";
  import { openWidget, closeWidget } from "./utils/uploader";
  import { scrollToHero } from "./utils/scrollToHero";

  import { getFormattedNumber, getFormattedTime } from "./utils/formatter";
  import Listing from "./Listing.svelte";
  export let id = -1;
  export let firebase;

  let isPending = false;
  let title = "";
  let price = "";
  let images = [];
  let imageThumbnails = [];
  let description = "";
  let phone = "";
  let location = "";

  let tags = [];
  let tagListString = "";

  $: isValid =
    title.trim().length &&
    description.trim().length &&
    phone.trim().length &&
    price &&
    location &&
    tags &&
    tags.length < 50 &&
    !Number.isNaN(+price);

  const generateTags = tagListString => {
    const tagListStringTrimmed = tagListString.trim();
    if (tagListStringTrimmed.length === 0) {
      tags = [];
      return;
    }

    const updatedTags = tagListStringTrimmed
      .toLowerCase()
      .split(/[, ]+/)
      .map(tag => tag.replace(/^#/, ""));

    tags = [...new Set(updatedTags)];
  };

  const throttledGenerateTags = throttle(generateTags, 500);

  $: throttledGenerateTags(tagListString);

  const handleImageUpload = () => {
    openWidget(result => {
      if (result.event === "success") {
        const { secure_url, thumbnail_url } = result.info;
        images = [...images, secure_url].slice(0, 15);
        imageThumbnails = [...imageThumbnails, thumbnail_url].slice(0, 15);
      }
    });
  };

  function handleSubmit() {
    closeWidget();

    isPending = true;
    const db = firebase.firestore();
    db.collection("posts")
      .add({
        title: title.substring(0, 100),
        price: Math.min(+price, 999999999),
        images,
        imageThumbnails,
        description: description.substring(0, 500),
        phone: phone.substring(0, 15),
        location: location.substring(0, 50),
        tags: tags.slice(0, 50),
        createdOn: firebase.firestore.Timestamp.fromDate(new Date())
      })
      .then(function(docRef) {
        title = "";
        price = "";
        images = [];
        imageThumbnails = [];
        tags = [];
        description = "";
        phone = "";
        location = "";
        isPending = false;
        tagListString = "";
        scrollToHero();
      })
      .catch(function(error) {
        console.error("Error adding document: ", error);
        isPending = false;
      });
  }
</script>

<style type="text/scss">
  @import "./style/variables.scss";

  .form-inputs {
    display: flex;
    flex-direction: column;

    label {
      margin-bottom: 1rem;
      display: flex;
      flex-direction: column;

      span {
        text-transform: uppercase;
        font-size: 0.8em;
        margin-bottom: 0.4em;
        opacity: 0.7;
      }
    }
  }

  .card {
    max-width: 38rem;
    margin: 1rem auto 0;
    margin-bottom: 1rem;
    width: 100%;
    position: relative;
  }

  .btn-upload {
    margin-bottom: 1rem;
  }

  .thumbnails-uploaded {
    display: flex;
    flex-wrap: wrap;

    img {
      border-radius: 0.2rem;
      margin-right: 0.5rem;
    }
  }

  .listing-preview {
    max-width: 38rem;
    width: 100%;
    margin: 0 auto;
    position: relative;

    &::before {
      content: "Preview";
      display: block;
      position: absolute;
      top: 0;
      right: 1rem;
      background: $color-accent;
      padding: 0.4rem 1rem;
      border-radius: 0 0 0.2rem 0.2rem;
      color: $color-white;
      font-size: small;
      font-weight: bold;
      z-index: 1;
    }
  }

  #new-post-form {
    position: absolute;
    top: -100px;
  }
</style>

<div class="card">
  <div id="new-post-form" />
  <div class="card-title">Create new ad</div>
  <br />
  <form class="form-inputs" on:submit|preventDefault={handleSubmit}>
    <label>
      <span>Title</span>
      <input
        id="new-post-form-title"
        type="text"
        bind:value={title}
        maxlength="100"
        required
        placeholder="Add a title to your ad" />
    </label>
    <label>
      <span>Price</span>
      <input
        type="number"
        bind:value={price}
        step="0.01"
        min="0"
        max="999999999"
        required
        placeholder="Add your pricing" />
    </label>
    <label>
      <span>Description</span>
      <textarea
        type="text"
        bind:value={description}
        maxlength="500"
        required
        placeholder="Add a description for your ad" />
    </label>
    <label>
      <span>Phone</span>
      <input
        type="tel"
        bind:value={phone}
        required
        maxlength="15"
        placeholder="Add your contact number" />
    </label>
    <label>
      <span>Location</span>
      <input
        type="text"
        bind:value={location}
        maxlength="50"
        required
        placeholder="Add your location" />
    </label>
    <label>
      <span>Tags</span>
      <input
        type="text"
        bind:value={tagListString}
        maxlength="250"
        placeholder="Add your tags" />
    </label>

    {#if imageThumbnails && imageThumbnails.length}
      <label>
        <span>Uploaded Photos</span>
        <div class="thumbnails-uploaded">
          {#each imageThumbnails as thumbnail}
            <img src={thumbnail} alt="Preview of uploaded image" />
          {/each}
        </div>
      </label>
    {/if}

    <button class="btn btn-upload" type="button" on:click={handleImageUpload}>
      Upload photos
    </button>

    <button
      class="btn"
      disabled={!isValid || isPending}
      class:disabled={!isValid || isPending}>
      Submit
    </button>

    {#if isPending}
      <div class="loader" transition:fly />
    {/if}
  </form>
</div>

<div class="listing-preview">
  <Listing
    {tags}
    {id}
    title={title || 'Your title here'}
    {price}
    {images}
    {imageThumbnails}
    description={description || 'Add a description for your ad'}
    phone={phone || '000 000 0000'}
    location={location || 'Add your location'} />
</div>
